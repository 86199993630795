import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import './contact.css'
import city1 from '../../img/city_1.jpg'
import city2 from '../../img/city_2.jpg'
import city3 from '../../img/city_3.jpg'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <div className="flex flex-col xl:flex-row justify-between h-full">
          <div className="w-full min-h-screen">
            <iframe
              className="flex-grow w-full h-full"
              src="https://airtable.com/embed/shrhSMUmfBv6JdgTY?backgroundColor=red"
            />
          </div>

          <div className="flex flex-col flex-shrink-0 justify-around items-center bg-appliscale">
            <div className="note flex flex-row">
              <address className="flex flex-col justify-center p-4 w-52 text-center">
                <h className="text-appliscale text-xl">Poland</h>
                <p>Życzkowskiego 14</p>
                <p>31-864 Kraków</p>
                <p>1st floor</p>
                <p>KPT Building</p>
              </address>
              <img className="object-fill h-48 w-72" src={city1} />
            </div>

            <div className="note flex flex-row">
              <address className="flex flex-col justify-center p-4 w-52 text-center">
                <h className="text-appliscale text-xl">Ireland</h>
                <p>12 Ard Na Cuan</p>
                <p>Whiterock South</p>
                <p>Wexford Y35K0D2</p>
              </address>
              <img className="object-fill h-48 w-72" src={city2} />
            </div>

            <div className="note flex flex-row">
              <address className="flex flex-col justify-center p-4 w-52 text-center">
                <h className="text-appliscale text-xl">Hungary</h>
                <p>1087 Budapest</p>
                <p>Könyves Kálmán blvd. 76</p>
                <p>3rd floor Nr 313</p>
                <p>Módusz Office</p>
              </address>
              <img className="object-fill h-48 w-72" src={city3} />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
